import conf from "./config";
import AsyncStorage from '@callstack/async-storage';

const loginUser = (postData) => {
  return fetch(conf.base_api + "users/signin", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

const updateUser = (xtoken, postData) => {
  return fetch(conf.base_api + "users/update/info", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

const updatePwd = (xtoken, postData) => {
  return fetch(conf.base_api + "users/update/pwd", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

const findSetup = (xtoken) => {
  return fetch(conf.base_api + "setups/find", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const setSetup = (xtoken, postData) => {
  return fetch(conf.base_api + "setups/set", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const refreshUser = (xtoken) => {
  return fetch(conf.base_api + "setups/refresh", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
/** accounts */
const newAccount = (xtoken, postData) => {
  return fetch(conf.base_api + "children/add", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findAccounts = (xtoken) => {
  return fetch(conf.base_api + "children/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editAccount = (xtoken, postData, id) => {
  return fetch(conf.base_api + "children/edit/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteAccount = (xtoken, id) => {
  return fetch(conf.base_api + "children/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** apps */
const newApp = (xtoken, postData) => {
  return fetch(conf.base_api + "appslist/add", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findApps = (xtoken) => {
  return fetch(conf.base_api + "appslist/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editApp = (xtoken, postData, id) => {
  return fetch(conf.base_api + "appslist/edit/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteApp = (xtoken, id) => {
  return fetch(conf.base_api + "appslist/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** math groups */
const newMathGroup = (xtoken, postData) => {
  return fetch(conf.base_api + "math/group/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      "Authorization": "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findMathGroups = (xtoken) => {
  return fetch(conf.base_api + "math/group/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editMathGroup = (xtoken, postData, id) => {
  return fetch(conf.base_api + "math/group/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteMathGroup = (xtoken, id) => {
  return fetch(conf.base_api + "math/group/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** math sections */
const newMathSection = (xtoken, postData) => {
  return fetch(conf.base_api + "math/section/add", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findMathSections = (xtoken) => {
  return fetch(conf.base_api + "math/section/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editMathSection = (xtoken, postData, id) => {
  return fetch(conf.base_api + "math/section/edit/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteMathSection = (xtoken, id) => {
  return fetch(conf.base_api + "math/section/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
/** math borana content */
const newMathBoranaContent = (xtoken, postData) => {
  return fetch(conf.base_api + "math/borana/content/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findMathBoranaContents = (xtoken) => {
  return fetch(conf.base_api + "math/borana/content/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editMathBoranaContent = (xtoken, postData, id) => {
  return fetch(conf.base_api + "math/borana/content/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteMathBoranaContent = (xtoken, id) => {
  return fetch(conf.base_api + "math/borana/content/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** math somali content */
const newMathSomaliContent = (xtoken, postData) => {
  return fetch(conf.base_api + "math/somali/content/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findMathSomaliContents = (xtoken) => {
  return fetch(conf.base_api + "math/somali/content/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editMathSomaliContent = (xtoken, postData, id) => {
  return fetch(conf.base_api + "math/somali/content/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteMathSomaliContent = (xtoken, id) => {
  return fetch(conf.base_api + "math/somali/content/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** math maasai content */
const newMathMaasaiContent = (xtoken, postData) => {
  return fetch(conf.base_api + "math/maasai/content/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findMathMaasaiContents = (xtoken) => {
  return fetch(conf.base_api + "math/maasai/content/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editMathMaasaiContent = (xtoken, postData, id) => {
  return fetch(conf.base_api + "math/maasai/content/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteMathMaasaiContent = (xtoken, id) => {
  return fetch(conf.base_api + "math/maasai/content/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** math videos - all */
const newMathVideo = (xtoken, postData) => {
  return fetch(conf.base_api + "math/video/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findMathVideos = (xtoken) => {
  return fetch(conf.base_api + "math/video/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editMathVideo = (xtoken, postData, id) => {
  return fetch(conf.base_api + "math/video/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteMathVideo = (xtoken, id) => {
  return fetch(conf.base_api + "math/video/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** math borana quiz  */
const newMathBoranaTest = (xtoken, postData) => {
  return fetch(conf.base_api + "math/borana/quiz/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findMathBoranaTests = (xtoken) => {
  return fetch(conf.base_api + "math/borana/quiz/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editMathBoranaTest = (xtoken, postData, id) => {
  return fetch(conf.base_api + "math/borana/quiz/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteMathBoranaTest = (xtoken, id) => {
  return fetch(conf.base_api + "math/borana/quiz/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** math somali quiz  */
const newMathSomaliTest = (xtoken, postData) => {
  return fetch(conf.base_api + "math/somali/quiz/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findMathSomaliTests = (xtoken) => {
  return fetch(conf.base_api + "math/somali/quiz/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editMathSomaliTest = (xtoken, postData, id) => {
  return fetch(conf.base_api + "math/somali/quiz/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteMathSomaliTest = (xtoken, id) => {
  return fetch(conf.base_api + "math/somali/quiz/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** math maasai quiz  */
const newMathMaasaiTest = (xtoken, postData) => {
  return fetch(conf.base_api + "math/maasai/quiz/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findMathMaasaiTests = (xtoken) => {
  return fetch(conf.base_api + "math/maasai/quiz/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editMathMaasaiTest = (xtoken, postData, id) => {
  return fetch(conf.base_api + "math/maasai/quiz/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteMathMaasaiTest = (xtoken, id) => {
  return fetch(conf.base_api + "math/maasai/quiz/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** dashboard */
const findDashboardMeta = (xtoken) => {
  return fetch(conf.base_api + "statistics/dashboard", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    console.log('res.status', res.status)
    if(res.status === 401 )
    {
      AsyncStorage.removeItem(conf.secret)
    .then((done) => {}).catch((error) =>{});
    }
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** environment groups */
const newEnvironmentGroup = (xtoken, postData) => {
  return fetch(conf.base_api + "environment/group/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      "Authorization": "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findEnvironmentGroups = (xtoken) => {
  return fetch(conf.base_api + "environment/group/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editEnvironmentGroup = (xtoken, postData, id) => {
  return fetch(conf.base_api + "environment/group/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteEnvironmentGroup = (xtoken, id) => {
  return fetch(conf.base_api + "environment/group/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** environment videos - all */
const newEnvironmentVideo = (xtoken, postData) => {
  return fetch(conf.base_api + "environment/video/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findEnvironmentVideos = (xtoken) => {
  return fetch(conf.base_api + "environment/video/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editEnvironmentVideo = (xtoken, postData, id) => {
  return fetch(conf.base_api + "environment/video/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteEnvironmentVideo = (xtoken, id) => {
  return fetch(conf.base_api + "environment/video/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** language groups */
const newLanguageGroup = (xtoken, postData) => {
  return fetch(conf.base_api + "language/group/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      "Authorization": "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findLanguageGroups = (xtoken) => {
  return fetch(conf.base_api + "language/group/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editLanguageGroup = (xtoken, postData, id) => {
  return fetch(conf.base_api + "language/group/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteLanguageGroup = (xtoken, id) => {
  return fetch(conf.base_api + "language/group/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** Language videos - all */
const newLanguageVideo = (xtoken, postData) => {
  return fetch(conf.base_api + "language/video/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findLanguageVideos = (xtoken) => {
  return fetch(conf.base_api + "language/video/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editLanguageVideo = (xtoken, postData, id) => {
  return fetch(conf.base_api + "language/video/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteLanguageVideo = (xtoken, id) => {
  return fetch(conf.base_api + "language/video/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** language sections */
const newLanguageSection = (xtoken, postData) => {
  return fetch(conf.base_api + "language/section/add", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findLanguageSections = (xtoken) => {
  return fetch(conf.base_api + "language/section/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editLanguageSection = (xtoken, postData, id) => {
  return fetch(conf.base_api + "language/section/edit/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify(postData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteLanguageSection = (xtoken, id) => {
  return fetch(conf.base_api + "language/section/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};


/** Language borana content */
const newLanguageBoranaContent = (xtoken, postData) => {
  return fetch(conf.base_api + "language/borana/content/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findLanguageBoranaContents = (xtoken) => {
  return fetch(conf.base_api + "language/borana/content/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editLanguageBoranaContent = (xtoken, postData, id) => {
  return fetch(conf.base_api + "language/borana/content/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteLanguageBoranaContent = (xtoken, id) => {
  return fetch(conf.base_api + "language/borana/content/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

/** Language somali content */
const newLanguageSomaliContent = (xtoken, postData) => {
  return fetch(conf.base_api + "language/somali/content/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findLanguageSomaliContents = (xtoken) => {
  return fetch(conf.base_api + "language/somali/content/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editLanguageSomaliContent = (xtoken, postData, id) => {
  return fetch(conf.base_api + "language/somali/content/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteLanguageSomaliContent = (xtoken, id) => {
  return fetch(conf.base_api + "language/somali/content/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};


/** Language maasai content */
const newLanguageMaasaiContent = (xtoken, postData) => {
  return fetch(conf.base_api + "language/maasai/content/add", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const findLanguageMaasaiContents = (xtoken) => {
  return fetch(conf.base_api + "language/maasai/content/findall", {
    method: "Get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const editLanguageMaasaiContent = (xtoken, postData, id) => {
  return fetch(conf.base_api + "language/maasai/content/edit/" + id, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: postData,
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
const deleteLanguageMaasaiContent = (xtoken, id) => {
  return fetch(conf.base_api + "language/maasai/content/drop/" + id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + xtoken,
    },
    body: JSON.stringify({data:null}),
  })
  .then((res) => {
    return res.json();
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};
export {
  loginUser,
  updateUser,
  updatePwd,


  findSetup,
  setSetup,
  refreshUser,

  newAccount,
  findAccounts,
  editAccount,
  deleteAccount,

  newApp,
  findApps,
  editApp,
  deleteApp,

  newMathGroup,
  findMathGroups,
  editMathGroup,
  deleteMathGroup,

  newMathSection,
  findMathSections,
  editMathSection,
  deleteMathSection,

  newMathBoranaContent,
  findMathBoranaContents,
  editMathBoranaContent,
  deleteMathBoranaContent,

  newMathSomaliContent,
  findMathSomaliContents,
  editMathSomaliContent,
  deleteMathSomaliContent,

  newMathMaasaiContent,
  findMathMaasaiContents,
  editMathMaasaiContent,
  deleteMathMaasaiContent,

  newMathVideo,
  findMathVideos,
  editMathVideo,
  deleteMathVideo,

  newMathBoranaTest,
  findMathBoranaTests,
  editMathBoranaTest,
  deleteMathBoranaTest,

  newMathSomaliTest,
  findMathSomaliTests,
  editMathSomaliTest,
  deleteMathSomaliTest,

  newMathMaasaiTest,
  findMathMaasaiTests,
  editMathMaasaiTest,
  deleteMathMaasaiTest,

  findDashboardMeta,

  newEnvironmentGroup,
  findEnvironmentGroups,
  editEnvironmentGroup,
  deleteEnvironmentGroup,

  newEnvironmentVideo,
  findEnvironmentVideos,
  editEnvironmentVideo,
  deleteEnvironmentVideo,

  newLanguageGroup,
  findLanguageGroups,
  editLanguageGroup,
  deleteLanguageGroup,

  newLanguageSection,
  findLanguageSections,
  editLanguageSection,
  deleteLanguageSection,

  newLanguageVideo,
  findLanguageVideos,
  editLanguageVideo,
  deleteLanguageVideo,

  newLanguageBoranaContent,
  findLanguageBoranaContents,
  editLanguageBoranaContent,
  deleteLanguageBoranaContent,

  newLanguageSomaliContent,
  findLanguageSomaliContents,
  editLanguageSomaliContent,
  deleteLanguageSomaliContent,

  newLanguageMaasaiContent,
  findLanguageMaasaiContents,
  editLanguageMaasaiContent,
  deleteLanguageMaasaiContent,
};
