import logo200Image from 'assets/img/logo/logo_inv.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { FaGithub } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdBorderAll,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdKeyboardArrowDown,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdTextFields,
  MdViewCarousel,
} from 'react-icons/md';
import { FaTruckMoving, FaHome, FaUsers, FaUsersCog, FaSortNumericUp,FaChartBar,FaChartPie,FaMoneyCheckAlt, FaTree, FaAmilia} from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};



const peopleMenu = [
  { to: '/manage-accounts', name: 'User accounts', exact: false, Icon: MdTextFields },
];

const appsMenu = [
  { to: '/app-list', name: 'Apps', exact: false, Icon: MdTextFields },
];

const mathContent = [
  { to: '/math/groups', name: 'Groups', exact: false, Icon: MdAccountCircle },
  { to: '/math/sections', name: 'Sections', exact: false, Icon: MdAccountCircle },
  { to: '/math/videos', name: 'Videos', exact: false, Icon: MdAccountCircle },
  /** borana */
  { to: '/math/borana/content', name: 'Borana Content', exact: false, Icon: MdAccountCircle },
  // { to: '/math/borana/quizes', name: 'Borana Quizes', exact: false, Icon: MdAccountCircle },
  /** somali */
  { to: '/math/somali/content', name: 'Somali Content', exact: false, Icon: MdAccountCircle },
  // { to: '/math/somali/quizes', name: 'Somali Quizes', exact: false, Icon: MdAccountCircle },
  /** maasai */
  { to: '/math/maasai/content', name: 'Maasai Content', exact: false, Icon: MdAccountCircle },
  // { to: '/math/maasai/quizes', name: 'Maasai Quizes', exact: false, Icon: MdAccountCircle },
];

const envContent = [
  { to: '/environment/groups', name: 'Groups', exact: false, Icon: MdAccountCircle },
  /** borana */
  { to: '/environment/videos', name: 'Videos', exact: false, Icon: MdAccountCircle },
];

const langContent = [
  { to: '/language/groups', name: 'Groups', exact: false, Icon: MdAccountCircle },
  { to: '/language/sections', name: 'Sections', exact: false, Icon: MdAccountCircle },
  { to: '/language/videos', name: 'Videos', exact: false, Icon: MdAccountCircle },
  { to: '/language/borana', name: 'Borana Content', exact: false, Icon: MdAccountCircle },
  { to: '/language/somali', name: 'Somali Content', exact: false, Icon: MdAccountCircle },
  { to: '/language/maasai', name: 'Maasai Content', exact: false, Icon: MdAccountCircle },
];

const navItems = [
  { to: '/', name: 'Dashboard', exact: true, Icon: MdDashboard },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenPeople: false,
    isOpenApplist: false,
    isOpenMaths: false,
    isOpenEnv: false,
    isOpenLang: false,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              />
              <span className="text-white">
                {process.env.REACT_APP_NAME}
              </span>
            </SourceLink>
          </Navbar>

          <Nav vertical>
            {/* Dashboard */}
            <NavItem key={1} className={bem.e('nav-item')}>
              <BSNavLink
                id="navItem-dashboard-1"
                className=""
                tag={NavLink}
                to='/'
                activeClassName="active"
                exact={false}
              >
                <div className="d-flex">
                  <FaHome className={bem.e('nav-item-icon')}/>
                  <span className="align-self-start">{"dashboard".toLocaleUpperCase()}</span>
                </div>
              </BSNavLink>
            </NavItem>

            {/* People */}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('People')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaUsersCog className={bem.e('nav-item-icon')} />
                  <span className="">{"people".toLocaleUpperCase()}</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPeople
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPeople}>
              {peopleMenu.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className=""
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    {/* <Icon className={bem.e('nav-item-icon')} /> */}
                    <span className="showables">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            {/* Apps */}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Applist')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaUsersCog className={bem.e('nav-item-icon')} />
                  <span className="">{"Apps".toLocaleUpperCase()}</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenApplist
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenApplist}>
              {appsMenu.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className=""
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    {/* <Icon className={bem.e('nav-item-icon')} /> */}
                    <span className="showables">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            {/* maths */}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Maths')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaSortNumericUp className={bem.e('nav-item-icon')} />
                  <span className="">{"Maths".toLocaleUpperCase()}</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenMaths
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenMaths}>
              {mathContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className=""
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <span className="showables">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> 


            {/* env */}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Env')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaTree className={bem.e('nav-item-icon')} />
                  <span className="">{"Environment".toLocaleUpperCase()}</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenEnv
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenEnv}>
              {envContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className=""
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <span className="showables">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> 

            {/* lang */}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Lang')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaAmilia className={bem.e('nav-item-icon')} />
                  <span className="">{"Language".toLocaleUpperCase()}</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenLang
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenLang}>
              {langContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className=""
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <span className="showables">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>            
            
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
