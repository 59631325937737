import { STATE_LOGIN, STATE_LOGGEDIN } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { Provider } from "./components/Context";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import AsyncStorage from '@callstack/async-storage';
import conf from "./api/config";

/** used */
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const SetupPage = React.lazy(() => import('pages/SetupPage'));
const ProfilePage = React.lazy(() => import('pages/ProfilePage'));
const AppListsPage = React.lazy(() => import('pages/AppListsPage'));
const AccountsPage = React.lazy(() => import('pages/AccountsPage'));
const MathGroupsPage = React.lazy(() => import('pages/MathGroupsPage'));
const MathSectionsPage = React.lazy(() => import('pages/MathSectionsPage'));
const MathBoranaContentPage = React.lazy(() => import('pages/MathBoranaContentPage'));
const MathVideosPage = React.lazy(() => import('pages/MathVideosPage'));
const MathBoranaQuizesPage = React.lazy(() => import('pages/MathBoranaQuizesPage'));

const MathSomaliContentPage = React.lazy(() => import('pages/MathSomaliContentPage'));
const MathSomaliQuizesPage = React.lazy(() => import('pages/MathSomaliQuizesPage'));

const MathMaasaiContentPage = React.lazy(() => import('pages/MathMaasaiContentPage'));
const MathMaasaiQuizesPage = React.lazy(() => import('pages/MathMaasaiQuizesPage'));

const EnvironmentGroupsPage = React.lazy(() => import('pages/EnvironmentGroupsPage'));
const EnvironmentVideosPage = React.lazy(() => import('pages/EnvironmentVideosPage'));

const LanguageGroupsPage = React.lazy(() => import('pages/LanguageGroupsPage'));
const LanguageVideosPage = React.lazy(() => import('pages/LanguageVideosPage'));
const LanguageSectionPage = React.lazy(() => import('pages/LanguageSectionPage'));

const LanguageBoranaContentPage = React.lazy(() => import('pages/LanguageBoranaContentPage'));
const LanguageSomaliContentPage = React.lazy(() => import('pages/LanguageSomaliContentPage'));
const LanguageMaasaiContentPage = React.lazy(() => import('pages/LanguageMaasaiContentPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user : {},
      is_logged_in : false,
    };
    AsyncStorage.getItem(conf.secret)
    .then((results) => {
      if( results !== null )
      {
        let obje_results = JSON.parse(results);
        this.setState({
          user : obje_results.user,
          is_logged_in : true,
        });
      }
      // console.log('from storage', results);
    })
    .catch((error) => {
      this.setState({
        user : {},
        is_logged_in : false,
      });
      console.log('from storage error', error);
    });
  }
  switchStateHandler = newState => {
    console.log('new state', newState);
    this.setState({
      user : newState.user,
      is_logged_in : newState.is_logged_in,
    });
  };


  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
        <Provider value={{
            state: this.state,
            switchStateHandler: e => this.switchStateHandler(e),
          }}>
          <Switch>
            {!this.state.is_logged_in && (
                <LayoutRoute
                exact
                path="/"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_LOGIN} />
                )}
              />
            )}
            {this.state.is_logged_in && (
              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  {/* misc */}
                  <Route exact path="/" component={DashboardPage} />
                  <Route exact path="/co/setup" component={SetupPage} />
                  <Route exact path="/user/profile" component={ProfilePage} />
                  {/* people */}
                  <Route exact path="/manage-accounts" component={AccountsPage} />
                  {/* Apps */}
                  <Route exact path="/app-list" component={AppListsPage} />
                  {/* MATHEMATICS */}
                  {/* math groups */}
                  <Route exact path="/math/groups" component={MathGroupsPage} />
                  {/* math sections */}
                  <Route exact path="/math/sections" component={MathSectionsPage} />
                  {/* math borana content */}
                  <Route exact path="/math/borana/content" component={MathBoranaContentPage} />
                  {/* videos */}
                  <Route exact path="/math/videos" component={MathVideosPage} />
                  {/* quizes */}
                  <Route exact path="/math/borana/quizes" component={MathBoranaQuizesPage} />
                  {/* Somali */}
                  <Route exact path="/math/somali/content" component={MathSomaliContentPage} />
                  <Route exact path="/math/somali/quizes" component={MathSomaliQuizesPage} />
                  {/* maasai */}
                  <Route exact path="/math/maasai/content" component={MathMaasaiContentPage} />
                  <Route exact path="/math/maasai/quizes" component={MathMaasaiQuizesPage} />
                  {/* ENVIRONMENT */}
                  {/* environment groups */}
                  <Route exact path="/environment/groups" component={EnvironmentGroupsPage} />
                  <Route exact path="/environment/videos" component={EnvironmentVideosPage} />

                  {/* LANGUAGE */}
                  {/* language groups */}
                  <Route exact path="/language/groups" component={LanguageGroupsPage} />
                  <Route exact path="/language/sections" component={LanguageSectionPage} />
                  <Route exact path="/language/videos" component={LanguageVideosPage} />
                  {/* content */}
                  <Route exact path="/language/borana" component={LanguageBoranaContentPage} />
                  <Route exact path="/language/somali" component={LanguageSomaliContentPage} />
                  <Route exact path="/language/maasai" component={LanguageMaasaiContentPage} />

                </React.Suspense>
              </MainLayout>
              // <Redirect to="/" />
            )}
          </Switch>
        </Provider>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
