// export default {
//     base_api: "http://127.0.0.1:8080/pci/api/v1/",
//     secret:
//       "4F8E1AA13916564CB84AC9B923E90DDE46B11148C2CE86998C9F1459728C625583E882DEB7FCCAD648B2940CEE6001D6E8689E3404864DD2552732322B1E9397",
//     backend_media: "http://127.0.0.1:8080",
//     media_api: "http://127.0.0.1:3000",
//     developer_page: "https://softbucket.io",
//     developer_name: "softbucket.io",
//     tinymcekey:"rkupw6dsoi853jngm7sd6a3ohten4dcgdtu3fc1o0954oceq",
// };

export default {
  base_api: "https://ml-svr.m-lugha.com/pci/api/v1/",
  secret:
    "4F8E1AA13916564CB84AC9B923E90DDE46B11148C2CE86998C9F1459728C625583E882DEB7FCCAD648B2940CEE6001D6E8689E3404864DD2552732322B1E9397",
  backend_media: "https://ml-svr.m-lugha.com",
  media_api: "https://adminapp.m-lugha.com",
  developer_page: "https://softbucket.io",
  developer_name: "softbucket.io",
  tinymcekey:"rkupw6dsoi853jngm7sd6a3ohten4dcgdtu3fc1o0954oceq",
};