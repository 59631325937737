import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncStorage from '@callstack/async-storage';
import { Button, Form, FormGroup, Input, Label, Alert, } from 'reactstrap';
import { loginUser } from "../api/api";
import conf from "../api/config";
import { Consumer } from "../components/Context";

class AuthForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user:{},
      is_logged_in:0,
      email:'',
      password:'',
      remember: false,
      isValidMail:true,
      isValidPass:true,
      error:'',
      msg:'',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handlePassInput = this.handlePassInput.bind(this);
    this.handleRemInput = this.handleRemInput.bind(this);
    this.isValidEmailAddress = this.isValidEmailAddress.bind(this);
  }
  componentDidMount() {

  }

  componentWillUnmount() {

  }

  isValidEmailAddress = (email) => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return true;
    }
    return false;
  }
  handleEmailInput = (event) => {
    const v = event.target.value;
    // console.log('typed', v);
    if ( this.isValidEmailAddress(v) )
    {
        this.setState({...this.state, email: v, isValidMail: true});
    }
    else 
    {
        this.setState({...this.state, email: v, isValidMail: false});
    }
  };
  handlePassInput = (event) => {
    const v = event.target.value;
    this.setState({...this.state, password: v, isValidPass: true});
  };
  handleRemInput = (event) => {
    const v = event.target.checked;
    this.setState({...this.state, remember: v});
  };
  handleSubmit(context){
  };
  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <Consumer>
        {context => (
          <Form>
            {showLogo && (
              <div className="text-center pb-4">
                <img
                  src={logo200Image}
                  className="rounded"
                  style={{ width: 60, height: 60, cursor: 'pointer' }}
                  alt="logo"
                  onClick={onLogoClick}
                />
              </div>
            )}
            {this.state.error.length > 0 && (
              <Alert color="danger">{this.state.error}</Alert>
            )}
            {this.state.msg.length > 0 && (
              <Alert color="success">{this.state.msg}</Alert>
            )}
            <FormGroup>
              <Label for={usernameLabel}>{usernameLabel}</Label>
              <Input value={this.state.email} onChange={this.handleEmailInput} {...usernameInputProps}  />
            </FormGroup>
            <FormGroup>
              <Label for={passwordLabel}>{passwordLabel}</Label>
              <Input value={this.state.password} onChange={this.handlePassInput} {...passwordInputProps} />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input onChange={this.handleRemInput} type="checkbox" />{' '} Remember me
              </Label>
            </FormGroup>
            <hr />
            <Button
              onClick={() => {
                loginUser(this.state).then((response) => {
                  if( response.status !== 200 )
                  {
                    this.setState({...this.state, error:response.message});
                    return;
                  }
                  else
                  {
                    let newstate = {
                      user : response.data,
                      is_logged_in : true,
                    };
                    this.setState({...this.state, msg:response.message});
                    AsyncStorage.setItem(conf.secret, JSON.stringify(newstate));
                    context.switchStateHandler(newstate);
                    console.log('response', response);
                    return
                  }
                }).catch((error) => {
                  this.setState({...this.state, error:'connection error occured'});
                  return;
                  console.log('error', error);
                });
              }}
              size="lg"
              className="bg-gradient-theme-left border-0"
              block >
              Login
            </Button>
            <br></br>
            {children}
          </Form>
        )}
      </Consumer>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_LOGGEDIN = 'LOGGEDIN';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_LOGGEDIN]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default AuthForm;
