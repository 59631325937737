import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import withBadge from 'hocs/withBadge';
import AsyncStorage from '@callstack/async-storage';
import { Consumer } from "../../components/Context";
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdNotificationsActive,
  MdPersonPin,
  MdSettingsInputComponent,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import conf from "../../api/config";
import { Redirect } from 'react-router-dom';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenNotificationPopover: true,
      isNotificationConfirmed: true,
      isOpenUserCardPopover: false,
    };
  }
  signOut = () => {
    AsyncStorage.removeItem(conf.secret)
    .then((done) => {}).catch((error) =>{});
    window.location.href = '/';
    this.forceUpdate();
  };
  goTo = () => {
    window.location.href = '/co/setup';
  }
  openProfile = () => {
    window.location.href = '/user/profile';
  }
  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const { isNotificationConfirmed } = this.state;

    return (
      <Consumer>
        {context => (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar>
          {/* <SearchInput /> */}
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          
          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                src={conf.backend_media + '/' + context.state.user.pic}
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={context.state.user.fname}
                  avatar={conf.backend_media + '/' + context.state.user.pic}
                  subtitle={context.state.user.email}
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem onClick={this.openProfile} tag="button" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    <ListGroupItem onClick={this.goTo} tag="button" action className="border-light">
                      <MdSettingsInputComponent /> Setup
                    </ListGroupItem>
                    <ListGroupItem onClick={this.signOut} tag="button" action className="border-light">
                      <MdExitToApp /> Sign Out
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
        )}
      </Consumer>
    );
  }
}

export default Header;
